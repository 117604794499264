@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
}
@import "react-toastify/dist/ReactToastify.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scroll Bar CSS */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  z-index: 999 !important;
}

::-webkit-scrollbar-track {
  background: rgba(242, 242, 242, 0.1);
  z-index: 999 !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(166, 166, 166, 1);
  border-radius: 5px;
  opacity: 0.2;
  z-index: 999 !important;
}
.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.MuiAccordionSummary-content {
  display: flex !important;
  justify-content: space-between !important;
}
.ribbon-top-left {
  top: 80px;
  left: 256px;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon-top-left span {
  right: -25px;
  top: 45px;
  transform: rotate(-45deg);
}
.ribbon span {
  position: absolute;
  display: block;
  width: 230px;
  padding: 8px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.myn1 {
  animation: mad1 10s linear infinite;
}

@keyframes mad1 {
  0% {
    left: -20%;
  }
  100% {
    left: 100%;
  }
}

.myn2 {
  animation: mad2 10s linear infinite;
}

@keyframes mad2 {
  0% {
    right: -20%;
  }
  100% {
    right: 110%;
  }
}
